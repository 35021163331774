$(document).ready(function() {
  $('#slide').lightSlider({
      item:1,
      slideMove:2,
      easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
      speed:600,
      slideMargin: 0,
      pause:4000,
      auto:true,
      loop:true,
      adaptiveHeight:true,
      controls: true,

      responsive : [
          {
              breakpoint:800,
              settings: {
                  item:1,
                  slideMove:1,
                  slideMargin:1,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1
                }
          }
      ]
  });

//   $('.bxslider').bxSlider();


  $(function() {

	// config
    var maxBreakpoint = 1023; // maximum breakpoint
    var targetID = 'navigation'; // target ID (must be present in DOM)
    var triggerID = 'toggle-nav'; // trigger/button ID (will be created into targetID)

	// targeting navigation
	var n = document.getElementById(targetID);

	// nav initially closed is JS enabled
	n.classList.add('is-closed');

    // global navigation function
	function navi() {
		// when small screen, create a switch button, and toggle navigation class
		if (window.matchMedia("(max-width:" + maxBreakpoint +"px)").matches && document.getElementById(triggerID)==undefined) {
			n.insertAdjacentHTML('afterBegin','<button id='+triggerID+' title="open/close navigation"></button>');
			t = document.getElementById(triggerID);
			t.onclick=function(){ n.classList.toggle('is-closed');}
		}
		// when big screen, delete switch button, and toggle navigation class
        var minBreakpoint = maxBreakpoint + 1;
		if (window.matchMedia("(min-width: " + minBreakpoint +"px)").matches && document.getElementById(triggerID)) {
			document.getElementById(triggerID).outerHTML="";
		}
	}
	navi();

	// when resize or orientation change, reload function
	window.addEventListener('resize', navi);


});




});


$(document).ready(function() {
//version final du script
	$('a[href^="#"]').click(function(){
        var the_id = $(this).attr("href");
        var height_menu = 48 ;
        $('html, body').animate({scrollTop:$(the_id).offset().top - height_menu}, 1400);
        //attention à la durée de l'animation qui peur créer des bugs
        return false // retourner false permet de ne pas relancer l'animation
    });

});


